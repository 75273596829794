<template lang="pug">
.app(:data-step="step")
  .container.py-3(@submit.prevent="onSubmit", novalidate)
    .step.step-terms(v-if="step === 1")
      terms
      div(v-waypoint='{ active: true, callback: onWaypoint, options: intersectionOptions }')
    .step.step-register(v-if="step === 2")
      h2.title.text-center.text-white.mx-n3.mt-n3.px-3.py-4.mb-4 ลงทะเบียน
      form.d-grid.gap-4.mb-4(@submit.prevent="onSubmit", novalidate)
        .form-group
          label.form-label.fs-5.fw-bold.mb-3(for="name") ชื่อ - นามสกุล
            small.fs-12.fw-normal.opacity-50.d-block (ใช้เพื่อจับฉลาก และ ประกาศรางวัล)
          input#name.form-control.form-control-lg(
            type="text",
            v-model="form.Name",
            required
          )
          .invalid-feedback กรุณากรอก ชื่อ - นามสกุล

        .form-group
          label.form-label.fs-5.fw-bold.mb-3(for="phone") เบอร์ติดต่อ
            small.fs-12.fw-normal.opacity-50.d-block (ใช้เพื่อจับฉลาก และ ประกาศรางวัล)
          input#phone.form-control.form-control-lg(
            type="tel",
            v-model="form.Phone",
            minlength="9",
            maxlength="10",
            required
          )
          .invalid-feedback กรุณากรอก เบอร์ติดต่อ

        .form-group
          label.form-label.fs-5.fw-bold.mb-3.d-block บ้านแบบไหนคือบ้านในฝันของคุณ
          .row.g-2.text-center
            label.col-4(
              v-for="({ value, label }, index) in houses",
              :key="value",
              :for="`house-${value}`"
            )
              .house-item.bg-light.py-3
                img.house-item-image.rounded-circle.mb-2.mx-auto(
                  :src="require(`@/assets/images/house/${index + 1}.jpg`)"
                )
                span.d-block.lh-1.mb-2(v-html="label")
                input.d-none(
                  v-model="form.DreamHouse",
                  type="radio",
                  :value="value",
                  :id="`house-${value}`"
                ) 
                svg(
                  width="16",
                  height="16",
                  viewbox="0 0 16 16",
                  fill="#d8dbdf",
                  xmlns="http://www.w3.org/2000/svg"
                )
                  path(
                    d="M15.75 8C15.75 12.2802 12.2802 15.75 8 15.75C3.71978 15.75 0.25 12.2802 0.25 8C0.25 3.71978 3.71978 0.25 8 0.25C12.2802 0.25 15.75 3.71978 15.75 8ZM7.10356 12.1036L12.8536 6.35356C13.0488 6.15831 13.0488 5.84172 12.8536 5.64647L12.1465 4.93937C11.9512 4.74409 11.6346 4.74409 11.4393 4.93937L6.75 9.62869L4.56066 7.43934C4.36541 7.24409 4.04881 7.24409 3.85353 7.43934L3.14644 8.14644C2.95119 8.34169 2.95119 8.65828 3.14644 8.85353L6.39644 12.1035C6.59172 12.2988 6.90828 12.2988 7.10356 12.1036Z"
                  )
          .invalid-feedback(
            :class="{ 'd-block': !form.DreamHouse && submited }"
          ) กรุณาเลือก บ้านแบบไหนคือบ้านในฝันของคุณ

        .form-group
          label.form-label.fs-5.fw-bold.mb-3 เหตุผลที่คุณโดนใจบ้านในฝัน
          textarea#city.form-control.form-control-lg(
            type="text",
            v-model="form.Reason",
            rows="3",
            required
          )
          .invalid-feedback กรุณากรอก เหตุผลที่คุณโดนใจบ้านในฝัน

        p.text-center.opacity-50
          strong หมายเหตุ
          |
          | : กรุณากรอกข้อมูลให้ถูกต้องครบถ้วน <br> เพื่อประโยชน์ในการร่วมกิจกรรมของท่าน

    .step.step-success.text-center.d-flex.align-items-center(v-if="step === 3")
      .w-100
        happy-student.mb-5
        h2.h1.text-primary.mb-4 ขอบคุณที่เข้าร่วมกิจกรรม
        p.text-center.mb-3 เจ้าหน้าที่จะทำการติดต่อท่านไปตามข้อมูลที่ท่าน <br> ได้ทำการลงทะเบียนหากท่านได้รับรางวัล
        h4 ประกาศผลกิจกรรม
        p วันที่ 20 พฤษภาคม 2565 <br> เวลา 18.00 น

    .step.step-success.text-center.d-flex.align-items-center(v-if="step === 4")
      .w-100
        done-pana.mb-5
        h2.h1.text-primary.mb-4 คุณได้ทำการลงทะเบียนแล้ว
        p.text-center.mb-3 ท่านลงทะเบียนเข้าร่วมกิจกรรมเป็นที่เรียบร้อยแล้ว <br> เจ้าหน้าที่จะทำการติดต่อท่านไปตามข้อมูลที่ท่าน <br> ได้ทำการลงทะเบียนหากท่านได้รับรางวัล
        h4 ประกาศผลกิจกรรม
        p วันที่ 20 พฤษภาคม 2565 <br> เวลา 18.00 น

    .action.position-fixed.start-0.end-0.bottom-0.p-3
      template(v-if="step === 1")
        .pb-2.text-center.fs-14(for="acceptTerm") ข้าพเจ้ายอมรับในข้อตกลงเเละเงื่อนไข
        button.btn.btn-lg.btn-block.rounded-pill.text-white.w-100(
          type="button",
          :disabled="!hasScrolledToBottom || isLoading",
          @click="checkRegister",
          :class="!hasScrolledToBottom ? 'btn-secondary opacity-25' : 'btn-primary'"
        ) 
          span.spinner-border.spinner-border-sm(
            v-if="isLoading",
            role="status",
            aria-hidden="true"
          )
          span(v-else) ยอมรับ

      template(v-if="step === 2")
        button.btn.btn-primary.btn-lg.btn-block.rounded-pill.text-white.w-100(
          type="submit",
          :disabled="isLoading",
          @click="onSubmit"
        ) 
          span.spinner-border.spinner-border-sm(
            v-if="isLoading",
            role="status",
            aria-hidden="true"
          )
          span(v-else) ส่งคำตอบ

      template(v-if="step === 3 || step === 4")
        button.btn.btn-primary.btn-lg.btn-block.rounded-pill.text-white.w-100(
          type="button",
          @click="success"
        )
          span(v-if="step === 4") ปิดหน้าต่าง
          span(v-else) เสร็จสิ้น
</template>

<script>
import "./assets/bootstrap.scss";
import liff from "@line/liff";
import Terms from "@/components/Terms.vue";
import HappyStudent from "@/components/HappyStudent.vue";
import DonePana from "@/components/DonePana.vue";

const { GoogleSpreadsheet } = require("google-spreadsheet");
const creds = require("@/client_secret.json");

export default {
  components: { Terms, HappyStudent, DonePana },
  data() {
    return {
      sheetId: "1783gx_-5igz2ETUpLEYox_MThogv_HIA0kG-nxioJ4o",
      form: {},
      step: 1,
      profile: {},
      isLoading: false,
      submited: false,
      rows: [],
      hasScrolledToBottom: false,
      houses: [
        {
          label: "บ้านเดี่ยว<br>พูลวิลล่า",
          value: "บ้านเดี่ยวพูลวิลล่า",
        },
        {
          label: "บ้านสวน<br>บนสนามใหญ่",
          value: "บ้านสวนบนสนามใหญ่",
        },
        {
          label: "บ้านสไตล์<br>มินิมอล",
          value: "บ้านสไตล์มินิมอล",
        },
      ],

      intersectionOptions: {
        root: null,
        rootMargin: "0px 0px 0px 0px",
        threshold: [0, 1], // [0.25, 0.75] if you want a 25% offset!
      }, // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
    };
  },

  watch: {
    step() {
      window.scrollTo(0, 0);
    },
  },

  // created() {
  //   window.addEventListener("scroll", this.handleScroll);
  // },

  // destroyed() {
  //   window.removeEventListener("scroll", this.handleScroll);
  // },

  mounted() {
    liff
      .init({ liffId: "1655692268-o1d6w9aw" })
      .then(() => {
        if (!liff.isLoggedIn()) {
          liff.login();
        } else {
          liff
            .getProfile()
            .then((profile) => {
              this.profile = profile;
            })
            .catch((err) => {
              console.log("error", err);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  methods: {
    async onSubmit() {
      this.submited = true;
      const form = document.querySelector("form");
      if (!form.checkValidity() || !this.form.DreamHouse) {
        form.classList.add("was-validated");
      } else {
        this.isLoading = true;
        this.form.UserId = this.profile.userId;
        this.form.Time = new Date();
        this.form.AcceptTerm = this.form.AcceptTerm ? "ยอมรับ" : "ไม่ยอมรับ";

        const doc = new GoogleSpreadsheet(this.sheetId);
        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();
        const sheet = doc.sheetsByIndex[0];
        await sheet.addRow(this.form);

        this.isLoading = false;
        this.step = 3;
      }
    },

    success() {
      liff.closeWindow();
    },

    handleScroll() {
      var totalPageHeight = document.body.scrollHeight;
      var scrollPoint = window.scrollY + window.innerHeight;

      if (scrollPoint >= totalPageHeight) {
        this.hasScrolledToBottom = true;
      }
    },

    onWaypoint({ going }) {
      if (going === this.$waypointMap.GOING_IN) {
        this.hasScrolledToBottom = true;
      }
    },

    checkRegister() {
      this.step = 2;
      this.form.AcceptTerm = 1;
      // this.isLoading = true;
      // const doc = new GoogleSpreadsheet(this.sheetId);
      // await doc.useServiceAccountAuth(creds);
      // await doc.loadInfo();
      // const sheet = doc.sheetsByIndex[0];
      // const rows = await sheet.getRows();
      // this.isLoading = false;
      // const data = rows.filter((el) => {
      //   return el.UserId === this.profile.userId;
      // });

      // if (data.length) {
      //   this.step = 4;
      // } else {
      //   this.step = 2;
      //   this.form.AcceptTerm = 1;
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 26px;
  background: url(assets/images/title-bg.jpg);
  background-size: cover;
}
.house-item {
  &:active {
    opacity: 0.5;
  }

  input:checked + svg {
    fill: var(--bs-primary);
  }

  &-image {
    max-width: 75px;
  }
}

.step-success {
  min-height: calc(100vh - 120px);
}
</style>

<style lang="scss">
.app {
  overflow: hidden;
  padding-bottom: 80px;
  background: #fff;
  min-height: 100vh;

  &[data-step="1"] {
    padding-bottom: 115px;
  }

  &[data-step="3"] {
    background: url(assets/images/bg.jpg);
    background-size: cover;
  }
}
.action {
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: saturate(200%) blur(20px);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

  @media (min-width: 576px) {
    width: 575px;
    left: 50% !important;
    transform: translateX(-50%);
  }
}
body {
  max-width: 575px;
  margin: auto;

  @media (min-width: 576px) {
    background: #f8f8f8;
  }
}
</style>